import React from "react";
import { Link } from "gatsby";
import { Link as Scroll } from "react-scroll";
import { StaticImage } from "gatsby-plugin-image";
import DropDown from "../DropDown";

export default function Navbar(props) {
  return (
    <section
      className="navbar"
      style={{ backgroundColor: props.backgroundColor }}
    >
      <div className="navbar__content">
        <Link to="/">
          <StaticImage
            src="../../images/logo_completo.svg"
            alt="IFRSys"
            placeholder="tracedSVG"
            height={90}
            className="logo-image"
          />
        </Link>
        <DropDown backgroundColor={props.backgroundColor}>
          <Scroll
            to="about"
            activeClass="active"
            smooth={true}
            duration={700}
            offset={-50}
            style={{ display: props.display }}
          >
            Funcionalidades
          </Scroll>
          <Scroll
            to="slide"
            activeClass="active"
            smooth={true}
            duration={700}
            offset={-50}
            style={{ display: props.display }}
          >
            Empresas
          </Scroll>
          <Scroll
            to="plans-table"
            activeClass="active"
            smooth={true}
            duration={700}
            offset={-50}
            style={{ display: props.display }}
          >
            Planos
          </Scroll>
          <Scroll
            to="formulario-contato"
            activeClass="active"
            smooth={true}
            duration={700}
            offset={-50}
            style={{ display: props.display }}
          >
            Contato
          </Scroll>
        </DropDown>
        <div className="navbar__links">
          <Scroll
            to="about"
            activeClass="active"
            smooth={true}
            duration={700}
            offset={-50}
            style={{ display: props.display }}
          >
            Funcionalidades
          </Scroll>
          <Scroll
            to="slide"
            activeClass="active"
            smooth={true}
            duration={700}
            offset={-50}
            style={{ display: props.display }}
          >
            Empresas
          </Scroll>
          <Scroll
            to="plans-table"
            activeClass="active"
            smooth={true}
            duration={700}
            offset={-50}
            style={{ display: props.display }}
          >
            Planos
          </Scroll>
          <Scroll
            to="formulario-contato"
            activeClass="active"
            smooth={true}
            duration={700}
            offset={-50}
            style={{ display: props.display }}
          >
            Contato
          </Scroll>
        </div>
      </div>
    </section>
  );
}
