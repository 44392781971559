import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import HoverIcon from "../HoverIcon";
import { Link } from "gatsby";

export default function Footer() {
  return (
    <footer>
      <p>
        Política de Privacidade. Finacc Auditoria, Consultoria e Contabilidade
        (iCount Plus Contabilidade): CNPJ: 30.303.459/0001-25iCount Plus
        Tecnologia: CNPJ: 39.546.181/0001-91 Conselho Regional de Contabilidade:
        2SP041143
      </p>

      <Link to="/">
        <StaticImage
          src="../../images/logo.svg"
          alt="logo"
          placeholder="tracedSVG"
          height={48}
        />
      </Link>
      <div className="icones">
        <a
          href="https://www.linkedin.com/company/ifrsys/"
          target="_blank"
          rel="noreferrer"
        >
          <HoverIcon>
            M256,7.098C118.535,7.098,7.098,118.535,7.098,256c0,137.465,111.437,248.902,248.902,248.902
            c137.465,0,248.902-111.437,248.902-248.902C504.902,118.535,393.465,7.098,256,7.098z
            M187.455,352.802h-53.822V190.89h53.822 V352.802z
            M160.554,168.794h-0.363c-18.058,0-29.755-12.445-29.755-27.992c0-15.886,12.052-27.969,30.465-27.969
            c18.421,0,29.747,12.083,30.102,27.969C191.004,156.349,179.323,168.794,160.554,168.794z
            M381.564,352.805h-53.822v-86.627
            c0-21.759-7.792-36.613-27.271-36.613c-14.875,0-23.725,10.008-27.624,19.687c-1.42,3.464-1.783,8.276-1.783,13.125v90.425h-53.83
            c0,0,0.718-146.718,0-161.912h53.827v22.948c7.149-11.015,19.927-26.754,48.518-26.754c35.43,0,61.983,23.138,61.986,72.871
            V352.805z
          </HoverIcon>
        </a>
        <a
          href="https://www.instagram.com/ifrsysoficial/"
          target="_blank"
          rel="noreferrer"
        >
          <HoverIcon>
            M256,0c141.29,0 256,114.71 256,256c0,141.29 -114.71,256
            -256,256c-141.29,0 -256,-114.71 -256,-256c0,-141.29 114.71,-256
            256,-256Zm0,96c-43.453,0 -48.902,0.184 -65.968,0.963c-17.03,0.777
            -28.661,3.482 -38.839,7.437c-10.521,4.089 -19.444,9.56
            -28.339,18.455c-8.895,8.895 -14.366,17.818
            -18.455,28.339c-3.955,10.177 -6.659,21.808
            -7.437,38.838c-0.778,17.066 -0.962,22.515 -0.962,65.968c0,43.453
            0.184,48.902 0.962,65.968c0.778,17.03 3.482,28.661
            7.437,38.838c4.089,10.521 9.56,19.444 18.455,28.34c8.895,8.895
            17.818,14.366 28.339,18.455c10.178,3.954 21.809,6.659
            38.839,7.436c17.066,0.779 22.515,0.963 65.968,0.963c43.453,0
            48.902,-0.184 65.968,-0.963c17.03,-0.777 28.661,-3.482
            38.838,-7.436c10.521,-4.089 19.444,-9.56 28.34,-18.455c8.895,-8.896
            14.366,-17.819 18.455,-28.34c3.954,-10.177 6.659,-21.808
            7.436,-38.838c0.779,-17.066 0.963,-22.515 0.963,-65.968c0,-43.453
            -0.184,-48.902 -0.963,-65.968c-0.777,-17.03 -3.482,-28.661
            -7.436,-38.838c-4.089,-10.521 -9.56,-19.444
            -18.455,-28.339c-8.896,-8.895 -17.819,-14.366
            -28.34,-18.455c-10.177,-3.955 -21.808,-6.66
            -38.838,-7.437c-17.066,-0.779 -22.515,-0.963
            -65.968,-0.963Zm0,28.829c42.722,0 47.782,0.163
            64.654,0.933c15.6,0.712 24.071,3.318 29.709,5.509c7.469,2.902
            12.799,6.37 18.397,11.969c5.6,5.598 9.067,10.929
            11.969,18.397c2.191,5.638 4.798,14.109 5.509,29.709c0.77,16.872
            0.933,21.932 0.933,64.654c0,42.722 -0.163,47.782
            -0.933,64.654c-0.711,15.6 -3.318,24.071 -5.509,29.709c-2.902,7.469
            -6.369,12.799 -11.969,18.397c-5.598,5.6 -10.928,9.067
            -18.397,11.969c-5.638,2.191 -14.109,4.798 -29.709,5.509c-16.869,0.77
            -21.929,0.933 -64.654,0.933c-42.725,0 -47.784,-0.163
            -64.654,-0.933c-15.6,-0.711 -24.071,-3.318
            -29.709,-5.509c-7.469,-2.902 -12.799,-6.369
            -18.398,-11.969c-5.599,-5.598 -9.066,-10.928
            -11.968,-18.397c-2.191,-5.638 -4.798,-14.109
            -5.51,-29.709c-0.77,-16.872 -0.932,-21.932 -0.932,-64.654c0,-42.722
            0.162,-47.782 0.932,-64.654c0.712,-15.6 3.319,-24.071
            5.51,-29.709c2.902,-7.468 6.369,-12.799 11.968,-18.397c5.599,-5.599
            10.929,-9.067 18.398,-11.969c5.638,-2.191 14.109,-4.797
            29.709,-5.509c16.872,-0.77 21.932,-0.933
            64.654,-0.933Zm0,49.009c-45.377,0 -82.162,36.785
            -82.162,82.162c0,45.377 36.785,82.162 82.162,82.162c45.377,0
            82.162,-36.785 82.162,-82.162c0,-45.377 -36.785,-82.162
            -82.162,-82.162Zm0,135.495c-29.455,0 -53.333,-23.878
            -53.333,-53.333c0,-29.455 23.878,-53.333 53.333,-53.333c29.455,0
            53.333,23.878 53.333,53.333c0,29.455 -23.878,53.333
            -53.333,53.333Zm104.609,-138.741c0,10.604 -8.597,19.199
            -19.201,19.199c-10.603,0 -19.199,-8.595 -19.199,-19.199c0,-10.604
            8.596,-19.2 19.199,-19.2c10.604,0 19.201,8.596 19.201,19.2Z
          </HoverIcon>
        </a>
        <a
          href="https://www.facebook.com/IFRSys"
          target="_blank"
          rel="noreferrer"
        >
          <HoverIcon>
            M256,0C114.615,0,0,114.615,0,256S114.615,512,256,512,512,397.385,512,256,397.385,0,256,0Zm67.59,153.428s-26.194-2.064-36.513,1.746c-17.056,6.3-17.462,21.034-17.462,35.084v28.694h52.149l-7.62,54.888H269.615V409.333h-58.9V273.84H161.744V218.952h48.974V172.4c0-49.292,37.942-67.151,60.563-69.294s52.309,4.286,52.309,4.286Z
          </HoverIcon>
        </a>
      </div>
    </footer>
  );
}
